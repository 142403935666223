import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React from 'react'
import SEO from '../atoms/SEO'
import styled from 'styled-components'
import { CardsBlock } from '../organisms/Apatiya/CardsBlock'
import { FindYourSpecialist } from '../organisms/Apatiya/FindYourSpecialist'
import { HelpWithApatiya } from '../organisms/Apatiya/HelpWithApatiya'
import { HeroBlock } from '../organisms/Apatiya/HeroBlock'
import { HowItSection } from '../organisms/HowItSection'
import { ProductMicromarkupComponent } from '../atoms/SEO/Micromarkup/Product'
import { PsychologistsWithApatiya } from '../organisms/Apatiya/PsychologistsWithApatiya'
import { RightChoiseTariffSection } from '../organisms/RightChoiseTariffSection'
import { StartTheWork } from '../organisms/Apatiya/StartTheWork'
import { StepsToFind } from '../organisms/Apatiya/StepsToFind'
import { TariffBlock } from '../organisms/SeoTariffsAndBundles/TariffBlock'
import { TarrifPackages } from '../organisms/SeoTariffsAndBundles/TarrifPackages'
import { WhatIsApatiya } from '../organisms/Apatiya/WhatIsApatiya'
import { color } from '../styles/vars/colors'
import { graphql } from 'gatsby'
import { pick } from 'rambda'
import { size } from '../constants'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: var(--height);
  background: #dfebf9;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`

const Main = styled.div`
  & ${RightChoiseTariffSection} {
    background: ${color.background};
    padding-top: 71px;
    padding-bottom: 115px;

    @media (max-width: ${size.lg}) {
      padding-top: 31px;
      padding-bottom: 119px;
    }

    @media (max-width: ${size.md}) {
      padding-top: 24px;
      padding-bottom: 69px;
    }

    @media (max-width: ${size.sm}) {
      padding-top: 26px;
      padding-bottom: 44px;
    }

    @media (max-width: ${size.xs}) {
      padding-top: 16px;
      padding-bottom: 32px;
    }
  }

  & ${HowItSection} {
    @media (max-width: ${size.sm}) {
      padding-top: 40px !important;
    }
  }

  & section {
    max-width: 100vw;
  }
`

const Apatiya = ({ data }) => (
  <App>
    <SEO
      description="Онлайн консультация психолога при апатии ✔Быстрый подбор специалиста ✔Более 280 психологов →Запишитесь на онлайн-сессию на YouTalk"
      title="Психологическая помощь при апатии — консультация психотерапевта онлайн | YouTalk"
    >
      <ProductMicromarkupComponent name="Онлайн психолог работающий с апатией" />
    </SEO>
    <Page>
      <Header />
      <Main>
        <HeroBlock />
        <WhatIsApatiya />
        <CardsBlock />
        <HelpWithApatiya />
        <StepsToFind />
        <TariffBlock />
        <TarrifPackages />
        <FindYourSpecialist />
        <PsychologistsWithApatiya data={pick(['psychologists'], data)} />
        <StartTheWork />
      </Main>
      <Footer />
    </Page>
  </App>
)

export default Apatiya

export const query = graphql`
  query Apatiya {
    psychologists: allPsychologistsData(
      filter: {
        nearest_slot: { ne: null }
        visibility: { eq: "OPEN" }
        work_areas: {
          elemMatch: { tag: { eq: "Апатия, усталость, нехватка сил" } }
        }
      }
    ) {
      edges {
        node {
          id
          _id
          is_active
          about
          approaches {
            _id
            id
            name
          }
          audio
          birthday_date
          educations {
            speciality
            title
            type
            yearEnd
            yearStart
          }
          english
          family
          surname
          features
          gender
          individual
          messages
          is_active
          name
          non_working_areas {
            _id
            id
            tag
          }
          photoUrl
          professions {
            profession_name
          }
          self_appointment
          sync_messages
          start_of_practice
          video
          work_areas {
            _id
            group {
              name
              type
            }
            id
            tag
          }
        }
      }
      totalCount
    }
  }
`
